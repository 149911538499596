import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './components/AuthenticationUtility/AuthContext';

const ProtectedRoute = ({ element  }) => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn?element:<Navigate to="/account/login" />;
};

export default ProtectedRoute;
