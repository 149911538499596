import React, { useState, useEffect, useCallback } from "react";
import { Button, Offcanvas, NavItem } from "react-bootstrap";
import LogoutComponent from "./LogoutComponent";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";

const AuhtMenu = () => {
  const { isLoggedIn, userData, isModerator } = useAuth();
  const [show, setShow] = useState(false);
  // Handlers
  const handleShow = useCallback(() => setShow(true), []);
  const handleClose = useCallback(() => setShow(false), []);

  return (
    <>
      {isLoggedIn ? (
        <>
          <Button variant="none" onClick={handleShow}>
            {userData && (
              <>
                {userData.avatar_url ? (
                  <img
                    src={userData.avatar_url}
                    alt="User avatar"
                    width="40"
                    height="40"
                    className="rounded-circle"
                  />
                ) : (
                  <div
                    style={{ width: 40, height: 40 }}
                    className="d-flex bg-primary border-primary text-white fw-bold rounded-circle align-content-center align-items-center justify-content-center"
                  >
                    {userData.first_name?.[0] || "U"}
                  </div>
                )}
              </>
            )}
          </Button>

          <Offcanvas show={show} onHide={handleClose} placement="end">
            {userData && (
              <>
                <Offcanvas.Header closeButton className="bg-secondary">
                  <div className="d-flex align-items-center text-white">
                    <div>
                      {userData.avatar_url ? (
                        <img
                          src={userData.avatar_url}
                          alt="User avatar"
                          width="70"
                          height="70"
                          className="rounded"
                        />
                      ) : (
                        <div
                          style={{ width: 70, height: 70 }}
                          className="d-flex bg-white border-white text-primary fw-bold rounded align-items-center justify-content-center"
                        >
                          {userData.name?.[0] || "U"}
                        </div>
                      )}
                    </div>
                    <div className="ms-2">
                      <span>{userData.name} </span>
                      <span> {userData.email}</span>
                    </div>
                  </div>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  {isModerator && (
                    <div className="pb-2 mb-3 border-bottom">
                      <Link
                        to="/moderator/business/requests"
                        className="dropdown-item"
                      >
                        Moderator
                      </Link>
                    </div>
                  )}
                  <div>
                    <Link to="/account/manage" className="dropdown-item">
                      Manage Account
                    </Link>
                  </div>
                  <div>
                    <LogoutComponent />
                  </div>
                </Offcanvas.Body>
              </>
            )}
          </Offcanvas>
        </>
      ) : (
        <NavItem>
          <Link
            className="text-dark text-decoration-none ms-2"
            to={"/account/register"}
          >
            Register
          </Link>
          <Link
            className="text-dark text-decoration-none ms-2"
            to={"/account/login"}
          >
            Login
          </Link>
        </NavItem>
      )}
    </>
  );
};

export default AuhtMenu;
