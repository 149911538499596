import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Form, Button } from "react-bootstrap";
import apiReaderApps from "../Apis/ReaderApps";
import HelparAlert from "../Helpars/Alert";
 import LayoutProjects  from "../Layouts/LayoutProjects";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const ReaderAppChangePassword = () => {
  const { logout } = useAuth();
  const params = useParams();
  const [isLoading, setLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");

  const [validated, setValidated] = useState(false);

  const onChangeCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };
  const onChangeNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  const onChangeConfirmNewPassword = (event) => {
    setConfirmNewPassword(event.target.value);
  };

  const onSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      let ReaderAppObj = {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      };
      let response = await apiReaderApps.ChangePassword(
        logout,
        params.id,
        params.hash_id,
        ReaderAppObj
      );
      setStatus(response.status);
      setMessage(response.message);
      setErrors(response.errors);

      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    }
    setValidated(true);
    setLoading(false);
  };
  return (
    <LayoutProjects
      rootName="Readers"
      currentRootName={params.name + " > Change Password"}
      rootLink={"/business/projects/reader-app/list/" + params.hash_id}
    >
      <Card className="shadow-sm p-3 mb-5 rounded border border-0">
        <Card.Body className="d-flex">
          <Form
            noValidate
            validated={validated}
            onSubmit={onSubmit}
            className="w-100"
          >
            <HelparAlert
              status={status}
              message={message}
              errors={errors}
            ></HelparAlert>
            <Form.Group className="mb-3">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                value={currentPassword}
                onChange={onChangeCurrentPassword}
                placeholder="Enter Current Password"
                type="password"
                required
              />
              <Form.Control.Feedback type="invalid">
                Current Password is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                value={newPassword}
                onChange={onChangeNewPassword}
                placeholder="Enter New Password"
                type="password"
                required
              />
              <Form.Control.Feedback type="invalid">
                New Password is required
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                value={confirmNewPassword}
                onChange={onChangeConfirmNewPassword}
                placeholder="Enter Confirm New Password"
                type="password"
                required
              />
              <Form.Control.Feedback type="invalid">
                Confirm New Password is required
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isLoading}
            >
              {isLoading ? "Loading…" : "submit"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </LayoutProjects>
  );
};
export default ReaderAppChangePassword;
