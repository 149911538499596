import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import apiAccounts from "../Apis/Accounts";
import { useLocation } from "react-router-dom";
import HelparAlert from "../Helpars/Alert"; // Importing HelparAlert component

const ConfirmEmail = () => {
  const location = useLocation();
  const [alert, setAlert] = useState({
    status: null,
    message: "",
    errors: [],
  });
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeFromQuery = queryParams.get("code");
    const userIdFromQuery = queryParams.get("user_id");
    if (codeFromQuery) {
      handleConfirmEmail(userIdFromQuery, codeFromQuery);
    }
  }, [location.search]);

  const handleConfirmEmail = async (userId, code) => {
    // Reset alerts before validation
    setAlert((prevAlert) => ({
      ...prevAlert,
      status: null,
      message: "",
      errors: [],
    }));
    const ConfirmEmailData = {
      user_id: userId,
      code: code,
    };
    try {
      const response = await apiAccounts.ConfirmEmail(ConfirmEmailData);

      if (response) {
        setAlert((prevAlert) => ({
          ...prevAlert,
          status: response.status,
          message: response.message,
          errors: response.errors,
        }));
      }
    } catch (error) {
      setAlert((prevAlert) => ({
        ...prevAlert,
        status: "error",
        message: "Registration failed",
        errors: [error.response?.data?.message || "An error occurred"],
      }));
    }
  };

  return (
    <Container>
      {alert.status !== "success" ? (
        <>
          <h2 className="mt-3">Confirm email</h2>
          <HelparAlert
            status={alert.status}
            message={alert.message}
            errors={alert.errors}
          />
        </>
      ) : (
        <p className="fw-500 fs-4 mt-3">{alert.message}</p>
      )}
    </Container>
  );
};

export default ConfirmEmail;
