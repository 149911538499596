import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LayoutProjects  from '../Layouts/LayoutProjects';
import ReadersPartialCreate from './Partial';

const ReadersCreate = () => {
    const params = useParams();
    const navigate = useNavigate();
    
    const ListReaders = async () => {
        navigate("/projects/readers/list/" + params.hash_id);
    }
    
    return (
        <LayoutProjects rootName="Readers" currentRootName="Create" rootLink={"/projects/readers/list/" + params.hash_id}>
            <ReadersPartialCreate ListReaders={ListReaders } />
        </LayoutProjects>
    );
}
export default ReadersCreate;