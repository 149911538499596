/* eslint-disable import/no-anonymous-default-export */
import ApiBase from '../Apis/Base'

const url = "api/projects/"

export default {
    async Create(logout,body) {
        try {
             const res = await ApiBase.apiBase(url + 'create', 'POST',logout, body)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Update(logout,body) {
        try {
             const res = await ApiBase.apiBase(url + 'update', 'PUT',logout, body,false, true)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async list(logout,bussines_id) {
        try {
             const res = await ApiBase.apiBase(url + 'list/'+bussines_id, 'GET',logout)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },async GetById(logout,id) {
        try {
             const res = await ApiBase.apiBase(url + 'get_by_id/' + id, 'GET',logout)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    }, async GetStatistics(logout,id) {
        try {
             const res = await ApiBase.apiBase(url + 'get_statistics/' + id, 'GET',logout)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
