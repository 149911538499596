import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import ApiReaderApps from "../Apis/ReaderApps";
import HelparAlert from "../Helpars/Alert";
import PageLoading from "../Helpars/PageLoading"; 
 import LayoutProjects  from "../Layouts/LayoutProjects";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const ReaderAppUpdate = () => { 
  const { logout } = useAuth();
  const params = useParams();
  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");
  const [readerAppData, setReaderAppData] = useState({
    name: "",
    languages: "",
    alertCloseType: "",
    alertCloseIntervalTime: "",
  });

  // Memoize populateProjectData using useCallback
  const populateProjectData = useCallback(async () => {
    setPageLoading(true);
    try {
      let response = await ApiReaderApps.GetById(logout, params.id, params.hash_id);
      setReaderAppData({
        name: response.data.name || "",
        languages: response.data.language || "",
        alertCloseType: response.data.alert_close_type || "",
        alertCloseIntervalTime: response.data.alert_close_interval_time || "",
      });
    } catch (error) {
      setErrors([error.message]);
    } finally {
      setPageLoading(false);
    }
  }, [logout, params.id, params.hash_id]);

  // Add populateProjectData to the useEffect dependency array
  useEffect(() => {
    populateProjectData();
  }, [populateProjectData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReaderAppData({
      ...readerAppData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();

      let ReaderAppObj = {
        name: readerAppData.name,
        languages: readerAppData.languages,
        alert_close_type: readerAppData.alertCloseType,
        alert_close_interval_time: readerAppData.alertCloseIntervalTime,
      };

      try {
        let response = await ApiReaderApps.Update(logout, params.id, params.hash_id, ReaderAppObj);
        setStatus(response.status);
        setMessage(response.message);
        setErrors(response.errors || []);
      } catch (error) {
        setErrors([error.message]);
      }
    }

    setValidated(true);
    setLoading(false);
  };

  return (
    <LayoutProjects
      rootName="Readers"
      currentRootName="Update"
      rootLink={"/business/projects/reader-app/list/" + params.hash_id}
    >
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <Container fluid="md">
          <Card className="shadow-sm p-3 mb-5 rounded border border-0">
            <Card.Body className="d-flex">
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                className="w-100"
              >
                <HelparAlert status={status} message={message} errors={errors}></HelparAlert>
                
                {/* Form Fields */}
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">Name</Form.Label>
                  <Col sm="10">
                    <Form.Control
                      name="name"
                      value={readerAppData.name}
                      onChange={handleInputChange}
                      placeholder="Enter name"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Name is required
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm="2">Languages</Form.Label>
                  <Col sm="10">
                    <Form.Select
                      name="languages"
                      value={readerAppData.languages}
                      onChange={handleInputChange}
                      className="mb-3"
                    >
                      <option value="en">English</option>
                      <option value="ar">Arabic</option>
                    </Form.Select>
                  </Col>
                </Form.Group>

                {/* Alert Close Type Fields */}
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column>Alert Close Type</Form.Label>
                  <Col sm="10">
                    <Form.Check
                      inline
                      name="alertCloseType"
                      label="Close Button"
                      type="radio"
                      checked={readerAppData.alertCloseType === "button_close"}
                      value="button_close"
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Check
                      inline
                      name="alertCloseType"
                      label="Interval Time"
                      type="radio"
                      checked={readerAppData.alertCloseType === "interval_time"}
                      value="interval_time"
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Check
                      inline
                      name="alertCloseType"
                      label="Close Button & Interval Time"
                      type="radio"
                      checked={readerAppData.alertCloseType === "button_close_and_interval_time"}
                      value="button_close_and_interval_time"
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Invitation Duration Type is required
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>

                {/* Conditional Rendering for Alert Close Interval Time */}
                {(readerAppData.alertCloseType === "interval_time" || readerAppData.alertCloseType === "button_close_and_interval_time") && (
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm="2">Alert Close Interval Time</Form.Label>
                    <Col sm="4">
                      <InputGroup className="mb-3">
                        <Form.Control
                          name="alertCloseIntervalTime"
                          type="number"
                          value={readerAppData.alertCloseIntervalTime}
                          onChange={handleInputChange}
                          placeholder="Enter Interval Time"
                        />
                        <InputGroup.Text>Second</InputGroup.Text>
                      </InputGroup>
                      <Form.Text className="text-muted">
                        Enter a maximum of 60 seconds
                      </Form.Text>
                    </Col>
                  </Form.Group>
                )}

                <Button type="submit" variant="primary" size="lg" disabled={isLoading}>
                  {isLoading ? "Loading…" : "Submit"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Container>
      )}
    </LayoutProjects>
  );
};

export default ReaderAppUpdate;
