import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import svgImage from "./assets/img/logo-icon.svg";
const FormPreview = (props) => {
  return (
    <footer className="footer py-3 mt-5 border-top">
      <div
        className="d-flex flex-wrap justify-content-between align-items-center px-5"
        style={props.marginLeft && { marginLeft: props.marginLeft }}
      >
        <p class="col-md-3 mb-0 text-body-secondary ">
          {" "}
          <Image src={svgImage} width={40} /> © {new Date().getFullYear()}{" "}
          <strong style={{ color: "#0054a6" }}> KNTO Pass </strong>
        </p>
        <ul class="nav col-md-9 justify-content-end text-end">
          <li class="nav-item">
            <Link
              to="/terms-and-conditions/privacy"
              className="nav-link px-2 text-body-secondary"
            >
              Privacy
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};
export default FormPreview;
