/* eslint-disable no-const-assign */
import React from "react";
import { useParams } from "react-router-dom";
 import LayoutProjects  from "../Layouts/LayoutProjects";
import AccessesListPartial from "./ListPartial";

const AccessesList = () => {
  const params = useParams();
  return (
    <LayoutProjects
      rootName="Accesses"
      buttonUrl={"/business/projects/accesses/create/" + params.hash_id}
      buttonName="Send Access"
    >
      <AccessesListPartial />
    </LayoutProjects>
  );
};
export default AccessesList;
