import ApiBase from './Base'

const url = "api/accounts/"

export default {
    async Login(body) {
        try {
             const response = await ApiBase.apiBase(url+'login', 'POST',null, body,true,false)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    } ,
    async Refresh(body) {
        try {
             const response = await ApiBase.apiBase(url+'refresh', 'POST',null, body,true,false)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    } ,
    async Register(body) {
        try {
             const response = await ApiBase.apiBase(url+'register', 'POST',null, body,true,false)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    } 
    ,
    async ResendEmailConfirmation(body) {
        try {
             const response = await ApiBase.apiBase(url+'resend_email_confirmation', 'POST',null, body,true,false)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    } 
    ,
    async ConfirmEmail(body) {
        try {
             const response = await ApiBase.apiBase(url+'confirm_email', 'POST',null, body,true,false)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    } 
    ,
    async ForgotPassword(body) {
        try {
             const response = await ApiBase.apiBase(url+'forgot_password', 'POST',null, body,true,false)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    } 
    ,
    async ResetPassword(body) {
        try {
             const response = await ApiBase.apiBase(url+'reset_password', 'POST',null, body,true,false)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    } 
 }