import React, { Component } from "react";

export class SupportContactUs extends Component {
  static displayName = SupportContactUs.name;

  render() {
    return (
      <div>
        <section class="pt-4 pt-md-11">
          <div class="container">
            <div class="row align-items-center">
              <h2 className="mt-3">Contact KNTO Pass Support</h2>
              <div className="mt-5">
                <div className="mb-3">
                  <strong className="me-2">Email</strong>support@kntopass.com
                </div>
                <div>
                  <strong className="me-2">Phone</strong>+201149966335
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
