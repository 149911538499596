import ApiBase from '../Apis/Base'

const url = "api/moderator/access_module_packages/"

export default {
    async Create(logout,accessModuleId,body) {
        try {
             const res = await ApiBase.apiBase(url + 'create/' + accessModuleId , 'POST',logout, body)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    } 
    ,
    async List(logout,accessModuleId) {
        try {
             const res = await ApiBase.apiBase(url + 'list/' + accessModuleId , 'GET',logout)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    }
}
