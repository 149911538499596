import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, Form, Button, InputGroup } from 'react-bootstrap';
import ApiReaders from '../Apis/Readers';
import HelparAlert from '../Helpars/Alert';
import PageLoading from '../Helpars/PageLoading';
import { useAuth } from '../AuthenticationUtility/AuthContext';



const ReadersPartialUpdate = ({ListReaders, readers_id}) => {
    const { logout } = useAuth();

    const params = useParams();
    const [validated, setValidated] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(true);
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');
 
    const [readersData, setReadersData] = useState({
        name: '',
        serail_number: '',
    });


    useEffect(() => {
        populateReadersData();
     }, [])


    const populateReadersData = async () => {
        //const id = readers_id ? readers_id : params.id;
         let response = await ApiReaders.GetById(logout,params.id, params.hash_id);
        setReadersData({
            name: response.data.name !== null ? response.data.name : '',
            serialNumber: response.data.serial_number !== null ? response.data.serial_number : '',
        })
        setPageLoading(false);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setReadersData({
            ...readersData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;
        const id = readers_id ? readers_id : params.id;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            let ReadersObj = {
                name: readersData.name,
             }
            let response = await ApiReaders.Update(logout,id, params.hash_id, ReadersObj)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)
                 
        }
        setValidated(true);
        setLoading(false);
    };


    return (
        <>
            {isPageLoading ? (
                <PageLoading />
            ) : (
                <Container fluid="md">
                    <Card className="shadow-sm p-3 mb-5 rounded border border-0">
                        <Card.Body className="d-flex">
                            <Form noValidate validated={validated} onSubmit={handleSubmit} className="w-100" >
                                <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                                
                                <Form.Group className="mb-3">
                                    <Form.Label  sm="2">Name</Form.Label>
                                        <Form.Control name="name" value={readersData.name} onChange={handleInputChange} placeholder="Enter name" required />
                                        <Form.Control.Feedback type="invalid">
                                            name is required
                                        </Form.Control.Feedback>
                                </Form.Group>
                              
                                <Form.Group className="mb-3">
                                    <Form.Label  sm="2">Serail Number</Form.Label>
                                        <Form.Control   value={readersData.serialNumber}   placeholder="Enter serail number" readOnly />
                                        <Form.Control.Feedback type="invalid">
                                        Serail Number is required
                                        </Form.Control.Feedback>
                                </Form.Group>

                                <Button type="submit"
                                    variant="primary" size="lg"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading…' : 'submit'}
                                </Button>

                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            )}
        </>
    );
}
export default ReadersPartialUpdate;