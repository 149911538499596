/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useParams } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const SideMenu = (props) => {
  const params = useParams();
  return (
    <div>
      <ul
        className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start px-3"
        id="menu"
      >
        <li className="nav-item  mb-3">
          <Link
            to={"/business/projects/" + params.hash_id}
            className="nav-link align-middle text-light px-0 "
          >
            <Icon.HouseFill size={25} />{" "}
            <span className="ms-1 d-none d-sm-inline">Dashboard</span>
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link
            to={"/business/projects/readers/list/" + params.hash_id}
            className="nav-link align-middle text-light px-0"
          >
            <Icon.PhoneFill size={25} />{" "}
            <span className="ms-1 d-none d-sm-inline">Readers</span>
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link
            to={"/business/projects/reader-app/list/" + params.hash_id}
            className="nav-link align-middle text-light px-0"
          >
            <Icon.PhoneFill size={25} />{" "}
            <span className="ms-1 d-none d-sm-inline">Mobile Readers</span>
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link
            to={"/business/projects/access-modules/list/" + params.hash_id}
            className="nav-link px-0 align-middle text-light"
          >
            <Icon.LayersFill size={25} />{" "}
            <span className="ms-1 d-none d-sm-inline">Access Modules</span>{" "}
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link
            to={"/business/projects/forms/list/" + params.hash_id}
            className="nav-link px-0 align-middle text-light"
          >
            <Icon.LayersFill size={25} />{" "}
            <span className="ms-1 d-none d-sm-inline">Forms</span>{" "}
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link
            to={"/business/projects/accesses/list/" + params.hash_id}
            className="nav-link px-0 align-middle text-light"
          >
            <Icon.TicketFill size={25} />{" "}
            <span className="ms-1 d-none d-sm-inline">Accesses</span>{" "}
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link
            to={"/business/projects/accesses/requests/list/" + params.hash_id}
            className="nav-link px-0 align-middle text-light"
          >
            <Icon.TicketFill size={25} />{" "}
            <span className="ms-1 d-none d-sm-inline">Accesses Requests</span>{" "}
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link
            to={
              "/business/projects/accesses/invitations/list/" + params.hash_id
            }
            className="nav-link px-0 align-middle text-light"
          >
            <Icon.EnvelopeCheckFill size={25} />{" "}
            <span className="ms-1 d-none d-sm-inline">Access Invitations</span>{" "}
          </Link>
        </li>{" "}
        <li className="nav-item mb-3">
          <Link
            to={"/business/projects/access-logs/list/" + params.hash_id}
            className="nav-link px-0 align-middle text-light"
          >
            <Icon.CardChecklist size={25} />{" "}
            <span className="ms-1 d-none d-sm-inline">Access Logs</span>{" "}
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default SideMenu;
