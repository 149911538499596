import React, { useEffect, useState } from "react";
import ApiProjects from "../Apis/Projects";
import { Link, useParams } from "react-router-dom";
import { Image } from "react-bootstrap";
import Loading from "../Helpars/Loading";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const ProjectHeader = (props) => {
  const { logout } = useAuth();
  const params = useParams();
  const [projectsDataHeader, setProjectsDataHeader] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    populateBusinessData();
  }, [params.hash_id]);

  const populateBusinessData = async () => {
    const projectData = await ApiProjects.GetById(logout, params.hash_id);
    if(projectData&&projectData.data)
    {
    setProjectsDataHeader(projectData.data);
    if(projectData.data.theme_color)
    {
    const root = document.documentElement;
    root.style.setProperty("--bs-primary", projectData.data.theme_color);
  }
    setLoading(false);
  }
  };

  return (
    <>
      <div
        className="mb-3 p-2 border-top px-4"
        style={{ backgroundColor: "rgb(5 69 98)" }}
      >
        <div class="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            {isLoading ? (
              <Loading />
            ) : (
              <span className="d-flex align-content-center align-items-center justify-content-center ">
                <Link
                  to={"/business/projects/" + params.hash_id}
                  className="d-flex align-content-center align-items-center justify-content-center fw-bold text-decoration-none"
                  style={{ color: "white" }}
                >
                  {projectsDataHeader &&
                  projectsDataHeader.logo_url !== null ? (
                    <Image
                      src={projectsDataHeader.logo_url}
                      width={30}
                      height={30}
                      rounded
                    />
                  ) : (
                    <div
                      style={{ width: 30, height: 30 }}
                      className="d-flex bg-white border-white fs-6 text-primary fw-bold  align-content-center align-items-center justify-content-center rounded"
                    >
                      {projectsDataHeader &&
                        projectsDataHeader.name[0].toUpperCase()}
                    </div>
                  )}
                  <span className="ms-2 me-1">
                    {projectsDataHeader && projectsDataHeader.name}
                  </span>
                </Link>
                {props &&
                props.rootName !== undefined &&
                props.rootLink !== undefined ? (
                  <span className="text-white">
                    {" "}
                    <strong> {">"}</strong>{" "}
                    <Link to={props.rootLink} className="text-white">
                      {props.rootName}
                    </Link>
                  </span>
                ) : props.rootName !== undefined ? (
                  <span className="text-white">
                    {" "}
                    <strong> {">"}</strong> {props.rootName}
                  </span>
                ) : (
                  ""
                )}
                {props && props.currentRootName !== undefined && (
                  <span className="text-white">
                    {" "}
                    <strong> {">"}</strong> {props.currentRootName}
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="d-flex justify-content-end">
            {props &&
              props.buttonName !== undefined &&
              props.buttonUrl !== undefined && (
                <Link to={props.buttonUrl} className="btn btn-sm btn-light">
                  {props.buttonName}
                </Link>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ProjectHeader;
