import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Alert,
  Table,
  Modal,
  Button,
  Row,
  Col,
  InputGroup,
  Form,
} from "react-bootstrap";
import apiAccessLogs from "../Apis/AccessLogs";
import PageLoading from "../Helpars/PageLoading";
import HelparPagination from "../Helpars/Pagination";
import moment from "moment";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const AccessLogsPartialList = (props) => {
  const { logout } = useAuth();
  const params = useParams();
  const [accessLogsList, setAccessLogsList] = useState([]);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [isPageLoading, setPageLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryLoggedFrom, setSearchQueryLoggedFrom] = useState("");
  const [searchQueryLoggedTo, setSearchQueryLoggedTo] = useState("");
  const [accessLogStatus, setAccessLogStatus] = useState("");
  const [reasonModalShow, setReasonModalShow] = React.useState(false);
  const [reasonDate, setReasonDate] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page starts at 1
  const [pageCount, setPageCount] = useState(null); // Current page starts at 1
  const itemsPerPage = 20; // Number of items to display per page

  const handlePageChange = (page) => {
    setCurrentPage(page);
    ListAccessLogs(page, searchQuery, accessLogStatus);
  };
  useEffect(() => {
    ListAccessLogs(1);
  }, []);
  const ListAccessLogs = async (
    page,
    searchQueryData,
    accessLogStatus,
    searchQueryLoggedFrom,
    searchQueryLoggedTo
  ) => {
    let response = await apiAccessLogs.list(
      logout,
      params.hash_id,
      searchQueryData,
      accessLogStatus,
      searchQueryLoggedFrom,
      searchQueryLoggedTo,
      props.reader_id,
      props.acceess_module_id,
      props.form_id,
      page,
      itemsPerPage
    );
    setAccessLogsList(response.records);
    setStatus(response.status);
    setMessage(response.message);
    setPageCount(Math.ceil(response.total / itemsPerPage));
    setPageLoading(false);
  };

  const openReasonModal = (reason) => {
    let jsonArray = JSON.parse(reason);
    setReasonModalShow(true);
    setReasonDate(jsonArray);
  };
  const onChangeQuery = (e) => {
    setSearchQuery(e.target.value);
  };
  const onChangeQueryLoggedFrom = (e) => {
    setSearchQueryLoggedFrom(e.target.value);
  };
  const onChangeQueryLoggedTo = (e) => {
    setSearchQueryLoggedTo(e.target.value);
  };
  const onChangeAccessLogStatus = (e) => {
    setAccessLogStatus(e.target.value);
  };
  const handleSearch = () => {
    ListAccessLogs(1, searchQuery, accessLogStatus);
  };
  return (
    <>
      <Row>
        <Col md={2} className="mb-3">
          <Form.Control
            placeholder="Access Name"
            aria-label="Access Name"
            aria-describedby="Access Name"
            value={searchQuery}
            onChange={onChangeQuery}
            type="search"
          />
        </Col>

        <Col md={2} className="mb-3">
          <Form.Group className="mb-3">
            <Form.Select
              aria-label="Default select example"
              value={accessLogStatus}
              onChange={onChangeAccessLogStatus}
              className="mb-3"
            >
              <option value="">Select Status</option>
              <option value="0">Success</option>
              <option value="1">Fail</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3} className="mb-3">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon3">From</InputGroup.Text>
            <Form.Control
              placeholder="Logged from"
              aria-label="Logged from"
              aria-describedby="Logged from"
              value={searchQueryLoggedFrom}
              onChange={onChangeQueryLoggedFrom}
              type="datetime-local"
            />
          </InputGroup>
        </Col>
        <Col md={3} className="mb-3">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon3">To</InputGroup.Text>
            <Form.Control
              placeholder="Logged from"
              aria-label="Logged from"
              aria-describedby="Logged from"
              value={searchQueryLoggedTo}
              onChange={onChangeQueryLoggedTo}
              type="datetime-local"
            />
          </InputGroup>
        </Col>
        <Col md={2} className="mb-3">
          <Button variant="primary" id="button-addon2" onClick={handleSearch}>
            Search
          </Button>
        </Col>
      </Row>
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <>
          {status === "success" ? (
            <>
              <Table responsive="sm" striped="columns">
                <thead>
                  <tr>
                    <th>Access Name</th>
                    {!props.reader_id && <th>Reader</th>}
                    <th>logged_at</th>
                    <th>Status</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {accessLogsList.map((item) => (
                    <tr key={item.id}>
                      <td>{item.access.name}</td>
                      {!props.reader_id && <td>{item.reader_app.name}</td>}
                      <td>
                        {moment
                          .unix(item.logged_at)
                          .format("DD-MM-YYYY hh:mm A")}
                      </td>
                      <td>
                        {item.status === "fail" ? (
                          <Badge bg="danger">Fail</Badge>
                        ) : (
                          <Badge bg="success">Success</Badge>
                        )}
                      </td>
                      <td>
                        {item.status === "fail" ? (
                          <Button
                            className="btn-sm"
                            onClick={() => openReasonModal(item.reason)}
                          >
                            View Reason
                          </Button>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {/* Render the pagination component */}
              <HelparPagination
                pageCount={pageCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </>
          ) : (
            <Alert color="secondary" className="text-center">
              <h5 className="mb-3">{message}</h5>
            </Alert>
          )}
          <Modal
            show={reasonModalShow}
            onHide={() => setReasonModalShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className="text-primary"
              >
                Reason for access failed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ul>
                {" "}
                {reasonDate &&
                  reasonDate.map((item) => (
                    <li key={item} className="fs-6 mb-3">
                      {item}
                    </li>
                  ))}
              </ul>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};
export default AccessLogsPartialList;
