import React, { useEffect, useState } from "react";
import { Card, Form, Button, Figure, Row, Col } from "react-bootstrap";
import ApiManageAccount from "../../Apis/ManageAccount";
import ApiUsers from "../../Apis/Users";
import HelparAlert from "../../Helpars/Alert";
import PageLoading from "../../Helpars/PageLoading";
import { useAuth } from "../../AuthenticationUtility/AuthContext";

const UpdateProfile = () => {
  const { logout } = useAuth();
  const [validated, setValidated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isPageLoading, setPageLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    avatar_file: null,
  });
  useEffect(() => {
    populateProjectData();
  }, []);

  const populateProjectData = async () => {
    let response = await ApiUsers.Get(logout);
    setUserData({
      first_name:
        response.data.first_name !== null ? response.data.first_name : "",
      last_name:
        response.data.last_name !== null ? response.data.last_name : "",
    });
    setAvatarUrl(response.data.avatar_url);
    setPageLoading(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setUserData({
      ...userData,
      avatar_file: file,
    });
    setAvatarUrl(URL.createObjectURL(file));
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      // Send formData to the API to update the data and upload the image
      const formData = new FormData();
      formData.append("avatar_file", userData.avatar_file);
      formData.append("first_name", userData.first_name);
      formData.append("last_name", userData.last_name);

      let response = await ApiManageAccount.UpdateProfile(logout, formData);
      setStatus(response.status);
      setMessage(response.message);
      setErrors(response.errors);

      // Handle the API response as needed
      console.log("API response:", response);
    }
    setValidated(true);
    setLoading(false);
  };

  return (
    <>
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <Card className="shadow-sm p-3 mb-5 rounded border border-0">
          <Card.Body className="d-flex">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="w-100"
            >
              <HelparAlert
                status={status}
                message={message}
                errors={errors}
              ></HelparAlert>

              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  {" "}
                </Form.Label>
                <Col sm="10">
                  {avatarUrl != null ? (
                    <Figure>
                      <Figure.Image width={100} height={100} src={avatarUrl} />
                    </Figure>
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Avatar
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="file" onChange={handleImageUpload} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  First Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="first_name"
                    value={userData.first_name}
                    onChange={handleInputChange}
                    placeholder="First Name"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    name is required
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm="2">
                  Last Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    name="last_name"
                    value={userData.last_name}
                    onChange={handleInputChange}
                    placeholder="Last Name"
                  />
                </Col>
              </Form.Group>
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isLoading}
              >
                {isLoading ? "Loading…" : "submit"}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
export default UpdateProfile;
