import ApiBase from './Base'

const url = "api/readers/"

export default {
    async list(logout,hash_id, search = '', page, per) {
        try {
            const response = await ApiBase.apiBase(url + 'list/' + hash_id + '?search=' + search + '&page=' + page + '&per=' + per, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async GetById(logout,id, hash_id) {
        try {
            const urls = url + 'get_by_id/' + id + "/" + hash_id;
            const response = await ApiBase.apiBase(urls, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Create(logout,hash_id, body) {
        try {
            const urls = url + 'create/' + hash_id;
            const response = await ApiBase.apiBase(urls, 'POST',logout, body)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Update(logout,id, hash_id, body) {
        try {
            const urls = url + 'update/' + id + "/" + hash_id;
            const response = await ApiBase.apiBase(urls, 'PUT',logout, body)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Delete(logout,id, hash_id) {
        try {
            const urls = url + 'delete/' + id + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, 'DELETE',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
