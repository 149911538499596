import React from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const LandingAbout = () => {
  return (
    <section id="Home" class="pt-md-11 pt-5 mt-3 ">
      <div class="container p-5">
        <div className="row">
          <div className="col-md-6 ">
            <div className="align-items-center align-content-center align-self-center">
              <h1 class="fs-1 fw-bold mb-3" style={{ color: "#6e6e6e" }}>
                Welcome to <span class="text-primary">KNTO Pass </span>{" "}
                <br></br>
              </h1>
              <h2 class="fw-700" style={{ color: "#6e6e6e" }}>
                <span> Revolutionize Your Identity Management!</span>
              </h2>
              <p class="lead mb-6 mb-lg-8 fs-4" style={{ color: "#6e6e6e" }}>
                Empower your organization with a cutting-edge identity and
                access management platform from <strong>KNTO Pass</strong>.
                Elevate customer trust, foster stronger partner relationships,
                and seamlessly navigate the evolving landscape of digital
                interactions.
              </p>
              <div class="">
                <Link
                  to={"/business/request"}
                  class="btn btn-primary text-uppercase p-3 lift me-1"
                >
                  Request to Create Your Business <Icon.ArrowRight size={25} />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img
              src={require("../assets/img/main-photo.png")}
              width="100%"
              alt="KNTO Pass"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default LandingAbout;
