import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Card,
  Form,
  Button,
  Row,
  Col,
  Table,
} from "react-bootstrap";
import apiAccessModuleFeatures from "../../Apis/AccessModuleFeatures";
import apiAccessModulePackages from "../../Apis/AccessModulePackages";
import HelparAlert from "../../Helpars/Alert";
import { useAuth } from "../../AuthenticationUtility/AuthContext";

const ModeratorAccessModulePackagesCreate = () => {
  const { logout } = useAuth();

  const params = useParams();
  const [isLoading, setLoading] = useState(false);

  const [accessModuleScheme, setAccessModuleScheme] = useState("");
  const [title, setTitle] = useState("");
  const [featureTotalPrices, setFeatureTotalPrices] = useState("");
  const [price, setPrice] = useState("");
  const [durationNumber, setDurationNumber] = useState(null);
  const [durationType, setDurationType] = useState("");
  const [isRenew, setIsRenew] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [usersMinimum, setUsersMinimum] = useState("");
  const [accessMaximum, setAccessMaximum] = useState(null);

  const [accessModulePackagesList, setAccessModulePackagesList] = useState("");

  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");
  const [validated, setValidated] = useState(false);

  const onChangeAccessModuleScheme = (event) => {
    setAccessModuleScheme(event.target.value);
  };

  const onChangeTitle = (event) => {
    setTitle(event.target.value);
  };
  const onChangePrice = (event) => {
    setPrice(event.target.value);
  };

  const onChangeDurationNumber = (event) => {
    setDurationNumber(event.target.value);
  };
  const onChangeDurationType = (event) => {
    setDurationType(event.target.value);
  };
  const onChangeIsRenew = (event) => {
    setIsRenew(!isRenew);
  };
  const onChangeIsUpgrade = () => {
    setIsUpgrade(!isUpgrade);
  };

  const onChangeUsersMinimum = (event) => {
    setUsersMinimum(event.target.value);
  };

  const onChangeAccessMaximum = (event) => {
    setAccessMaximum(event.target.value);
  };

  useEffect(() => {
    GetTotalFeaturePrice();
    ListAccessModulePackages();
  }, []);
  const GetTotalFeaturePrice = async () => {
    let response = await apiAccessModuleFeatures.GetTotalFeaturesPrice(
      logout,
      params.access_module_id
    );
    setFeatureTotalPrices(response.data.feature_price);
  };

  const ListAccessModulePackages = async () => {
    let response = await apiAccessModulePackages.List(
      logout,
      params.access_module_id
    );
    setAccessModulePackagesList(response.records);
    setStatus(response.status);
    setMessage(response.message);
  };
  const onSubmit = async (event) => {
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      let BusinessObj = {
        title: title,
        features_price: featureTotalPrices,
        price: price,
        duration_type: durationType,
        duration_number: durationNumber,
        minimum_users: usersMinimum,
        is_renew: isRenew,
        is_upgrade: isUpgrade,
        access_maximum: accessMaximum,
        access_module_scheme_id: accessModuleScheme,
      };
      let response = await apiAccessModulePackages.Create(
        logout,
        params.access_module_id,
        BusinessObj
      );
      setStatus(response.status);
      setMessage(response.message);
      setErrors(response.errors);

      if (response.status === "success") {
        setAccessModuleScheme("");
        setTitle("");
        setPrice("");
        setDurationNumber(null);
        setDurationType("");
        setIsRenew(false);
        setIsUpgrade(false);
        setUsersMinimum("");
        setAccessMaximum(null);
        ListAccessModulePackages();
      }
    }
    setValidated(true);
    setLoading(false);
  };

  return (
    <div>
      <Container fluid="md">
        <Card className="shadow-sm p-3 mb-5 rounded border border-0">
          <Card.Body>
            <div>
              <Form
                noValidate
                validated={validated}
                onSubmit={onSubmit}
                className="w-100"
              >
                <h1 className="mb-5">Create Access Module Packages</h1>
                <HelparAlert
                  status={status}
                  message={message}
                  errors={errors}
                ></HelparAlert>
                <Form.Group className="mb-3">
                  <Form.Label>Total feature price</Form.Label>
                  <Form.Control value={featureTotalPrices} readonly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Scheme</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    value={accessModuleScheme}
                    onChange={onChangeAccessModuleScheme}
                    className="mb-3"
                    required
                  >
                    <option value="">select</option>
                    <option value="1">Duration</option>
                    <option value="2">OneUse</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    Scheme is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>title</Form.Label>
                  <Form.Control
                    value={title}
                    onChange={onChangeTitle}
                    placeholder="Enter title"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    title is required
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    value={price}
                    onChange={onChangePrice}
                    placeholder="Enter Price"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Price is required
                  </Form.Control.Feedback>
                </Form.Group>
                {accessModuleScheme === "1" && (
                  <>
                    <Row className="mb-3">
                      <Col>
                        <Form.Group>
                          <Form.Label>Duration</Form.Label>
                          <Form.Control
                            value={durationNumber}
                            onChange={onChangeDurationNumber}
                            placeholder="Enter duration number"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Duration number is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label> type</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={durationType}
                            onChange={onChangeDurationType}
                          >
                            <option value="">select</option>
                            <option value="day">Day</option>
                            <option value="week">Week</option>
                            <option value="month">Month</option>
                            <option value="year">Year</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Check
                      inline
                      className="mb-2"
                      name="isRenew"
                      label="isRenew"
                      type="checkbox"
                      checked={isRenew}
                      onChange={onChangeIsRenew}
                    />
                  </>
                )}
                <Form.Check
                  inline
                  className="mb-2"
                  name="isUpgrade"
                  label="isUpgrade"
                  type="checkbox"
                  checked={isUpgrade}
                  onChange={onChangeIsUpgrade}
                />

                <Form.Group className="mb-3">
                  <Form.Label>Users Minimum</Form.Label>
                  <Form.Control
                    value={usersMinimum}
                    onChange={onChangeUsersMinimum}
                    placeholder="Enter Users Minimum"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Users Minimum is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Access Maximum</Form.Label>
                  <Form.Control
                    value={accessMaximum}
                    onChange={onChangeAccessMaximum}
                    placeholder="Enter Access Maximum"
                  />
                </Form.Group>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  disabled={isLoading}
                >
                  {isLoading ? "Loading…" : "submit"}
                </Button>
              </Form>
            </div>

            <Table responsive="sm" striped="columns">
              <thead>
                <tr>
                  <th>Scheme</th>
                  <th>Title</th>
                  <th>Price</th>
                  <th>Duration</th>
                  <th>Minimum Users</th>
                  <th>Access Maximum</th>
                </tr>
              </thead>
              <tbody>
                {accessModulePackagesList &&
                  accessModulePackagesList.map((item) => (
                    <tr key={item.id}>
                      <td>{item.access_module_scheme}</td>
                      <td>{item.title}</td>
                      <td>{item.price}</td>
                      <td>{item.duration_number + item.duration_type}</td>
                      <td>{item.minimum_users}</td>
                      <td>{item.access_maximum}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};
export default ModeratorAccessModulePackagesCreate;
