import React from "react";
 import LayoutProjects  from "../Layouts/LayoutProjects";
import { useParams } from "react-router-dom";
import AccessesPartialList from "../Accesses/ListPartial";
const ReaderAppAccesses = () => {
  const params = useParams();
  return (
    <LayoutProjects
      rootName="Readers"
      rootLink={"/business/projects/reader-app/list/" + params.hash_id}
      currentRootName={params.name + " > Accesses"}
    >
      <AccessesPartialList reader_id={params.id} />
    </LayoutProjects>
  );
};
export default ReaderAppAccesses;
