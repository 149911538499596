import React from "react";
 import LayoutProjects  from "../Layouts/LayoutProjects";
import { useParams } from "react-router-dom";
import AccessLogsPartialList from "../AccessLogs/ListPartial";
const AccessModuleslAccessLogsList = () => {
  const params = useParams();
  return (
    <LayoutProjects
      rootName="Access Modules"
      rootLink={"/business/projects/access-modules/list/" + params.hash_id}
      currentRootName={params.name + " > Access Logs"}
    >
      <AccessLogsPartialList acceess_module_id={params.id} />
    </LayoutProjects>
  );
};
export default AccessModuleslAccessLogsList;
