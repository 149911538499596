// Loading.js
import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = () => {
  return (
    <>
      <Spinner animation="border" />
    </>
  );
};

export default Loading;
