import React, { useEffect, useState } from 'react';
import { Card, Button, Form, Container, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ApiAccessModuleForms from '../Apis/AccessModuleForms';
import HelparAlert from '../Helpars/Alert';
import apiAccesses from '../Apis/Accesses';
import Footer from '../Footer';
import CustomFieldsIndex from '../CustomFields/Index';
import { useAuth } from "../AuthenticationUtility/AuthContext";

const FormPreview = (props) => {
    const {  logout } = useAuth();
    const params = useParams();
    const [isLoading, setLoading] = useState(false);
    const [formData, setForm] = useState(null);
    const [customFieldData, setCustomFieldData] = useState([]);
    const [name, setName] = useState('');
    const [identifierValue, setIdentifierValue] = useState('');
    const [formAlert, setFormAlert] = useState('');
    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');
    const [validated, setValidated] = useState(false);

    const onChangeName = (event) => {
        const maxCharacterCount = 20;
        if (event.target.value.length <= maxCharacterCount) {
            setName(event.target.value);
        }
    }

    const onChangeIdentifierValue = (event) => {
        setIdentifierValue(event.target.value);
    }
    useEffect(() => {
        AccessModuleFormData()
    }, [])

    const AccessModuleFormData = async () => {
        let response = await ApiAccessModuleForms.GetByHashId(logout,params.hash_id);
        setForm(response.data)
        if (response.data.deleted_at) {
            setFormAlert("This form has been removed")
        }
        else {
            if (response.data.start_at > new Date().toISOString()) {
                setFormAlert("The form has not started")
            }
            if (response.data.end_at < new Date().toISOString()) {
                setFormAlert("The form is ended")
            }
        }
    }

    const onSubmit = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }
        else {
            event.preventDefault();
            let AccessObj = {
                name: name,
                identifier_value: identifierValue,
                identifier_type: "email",
            }
            let response = await apiAccesses.CreateByForm(logout,params.hash_id, AccessObj)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)

            if (response.status === "success") {
                event.preventDefault();
                setName('');
                setIdentifierValue('');
                setValidated(false);
            }
        }
        setLoading(false);
    }
    return (
        <>
            <Container tag="main" className="main-container">

                {formAlert ?
                    <Alert className="text-center fs-5 mt-5" variant='secondary'>{formAlert}</Alert>
                    :
                    <Card style={{ fontSize: 13 }}>
                        <Form noValidate validated={validated} onSubmit={onSubmit} className="w-100" >
                            {formData && formData.cover_url &&
                                <div className="bg-primary text-white mb-3 rounded" >
                                    <img src={formData.cover_url} style={{ maxHeight: 400, width: '100%' }} />
                                </div>
                            }
                            <Card.Header className="bg-white text-center border-0">
                                <h1 className="fs-4 text-primary">{formData && formData.title}</h1>
                                <p className="m-0 fs-6">{formData && formData.description}</p>
                            </Card.Header>
                            <Card.Body >

                                <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                                <div className="bg-primary text-white p-2 rounded mb-3 fs-6">Enter your information to obtain a pass</div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control value={name} onChange={onChangeName} placeholder="Enter name" required />
                                    <Form.Text className="text-muted">
                                        Enter a maximum of 20 characters
                                    </Form.Text>
                                    <Form.Control.Feedback type="invalid">
                                        name is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control value={identifierValue} onChange={onChangeIdentifierValue} placeholder="Enter Email" type="email" required />
                                    <Form.Control.Feedback type="invalid">
                                        Email is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <CustomFieldsIndex custom_fields={formData && formData.custom_fields} setCustomFieldData={setCustomFieldData} />
                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-between align-items-center bg-white text-white  m-2 ">
                                <Button type="submit"
                                    variant="primary" size="lg"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Loading…' : 'submit'}
                                </Button>

                            </Card.Footer>
                        </Form>
                    </Card>
                }

            </Container>
            <Footer />
        </>
    );
}
export default FormPreview;


