/* eslint-disable import/no-anonymous-default-export */
 import ApiBase from './Base'

const url = "api/terms_and_conditions/"

export default {
    async GetByType(logout,type) {
        try {
             const response = await ApiBase.apiBase(url + 'get_by_type/' + type, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },

}
