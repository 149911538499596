/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, Table, Button, Modal } from "react-bootstrap";
import apiBusinessRequests from "../../Apis/BusinessRequests";
import { LayoutModerator } from "../Layout";
import { useAuth } from "../../AuthenticationUtility/AuthContext";

const ModeratorBusinessesRequests = () => {
  const { logout } = useAuth();
  const params = useParams();
  const [requestsList, setRequestsList] = useState([]);
  const [requestData, setRequestData] = useState(null);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  useEffect(() => {
    ListRequests();
  }, []);

  const ListRequests = async () => {
    let response = await apiBusinessRequests.list(logout, params.hash_id);
    setRequestsList(response.records);
    setStatus(response.status);
    setMessage(response.message);
  };
  const handleShow = async (id) => {
    const request = requestsList.find((item) => item.id === parseInt(id));
    setShow(true);
    setRequestData(request);
  };
  const handleAction = async (id, requestType, actionType) => {
    let requesActionObj = {
      action_type: actionType,
      request_type: requestType,
    };
    let response = await apiBusinessRequests.Action(
      logout,
      id,
      requesActionObj
    );
    setStatus(response.status);
    setMessage(response.message);
    setShow(false);
    ListRequests();
  };

  return (
    <div>
      <LayoutModerator rootName="Businesses Requests">
        {status === "success" ? (
          <Table responsive="sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Created at</th>
                <th>Action</th>
                <th>Status</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {requestsList.map((item) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>{item.created_at}</td>
                  <td>{item.action}</td>
                  <td>{item.status}</td>
                  <td>
                    <Button
                      variant="primary"
                      onClick={() => handleShow(item.id)}
                    >
                      View Data
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Alert color="secondary">{message}</Alert>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            {requestData && (
              <>
                <div>
                  <strong>Name : </strong>
                  <span>{requestData.name}</span>
                </div>
                <div>
                  <strong>Phone : </strong>
                  <span>{requestData.phone}</span>
                </div>
                <div>
                  <strong>Email : </strong>
                  <span>{requestData.email}</span>
                </div>
                <div>
                  <strong>Details : </strong>
                  <span>{requestData.details}</span>
                </div>
                <div>
                  <strong>Potential users number : </strong>
                  <span>{requestData.potential_users_number}</span>
                </div>
                <div>
                  <strong>Business category name : </strong>
                  <span>{requestData.business_category_name}</span>
                </div>
                <div>
                  <strong>Country : </strong>
                  <span>{requestData.country}</span>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                handleAction(requestData.id, requestData.type_id, "accept")
              }
            >
              approved
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                handleAction(requestData.id, requestData.type_id, "reject")
              }
            >
              reject
            </Button>
          </Modal.Footer>
        </Modal>
      </LayoutModerator>
    </div>
  );
};
export default ModeratorBusinessesRequests;
