import React from "react";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const SideMenuModerator = () => {
  return (
    <div>
      <ul
        className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start px-3 "
        id="menu"
      >
        <li className="nav-item mb-2 ">
          <Link
            to={"/moderator/business/requests"}
            className="nav-link align-middle text-light px-0"
          >
            <Icon.PinMap />{" "}
            <span className="ms-1 d-none d-sm-inline">Businesses Requests</span>
          </Link>{" "}
          <Link
            to={"/moderator/business/create"}
            className="nav-link align-middle text-light px-0"
          >
            <Icon.PinMap />{" "}
            <span className="ms-1 d-none d-sm-inline">Businesses Create</span>
          </Link>
          <Link
            to={"/moderator/access-modules/list"}
            className="nav-link align-middle text-light px-0"
          >
            <Icon.PinMap />{" "}
            <span className="ms-1 d-none d-sm-inline">
              Access Modules Create
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default SideMenuModerator;
