import React from "react";
import { Row, Col } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

const LandingFooter = () => {
  return (
    <>
      <section id="Price" class=" mt-4 bg-primary text-white">
        <div class="container pt-5">
          <Row>
            <Col>
              <div className="px-3 mb-5">
                <h1 className="fs-1 fw-bold mb-3">KNTO Pass</h1>
                <h3>Revolutionize Your Identity Management!</h3>
                <p className="fs-6">
                  Empower your organization with a cutting-edge identity and
                  access management platform from KNTO Pass. Elevate customer
                  trust, foster stronger partner relationships, and seamlessly
                  navigate the evolving landscape of digital interactions.
                </p>
              </div>
            </Col>
            <Col>
              {/*<div className="text-center mb-5">*/}
              {/*    <a href="#" className="text-white ms-4"> <Icon.Facebook size={40} /></a>*/}
              {/*    <a href="#" className="text-white ms-4"> <Icon.Instagram size={40} /></a>*/}
              {/*    <a href="#" className="text-white ms-4"> <Icon.Linkedin size={40} /></a>*/}
              {/*    <a href="#" className="text-white ms-4"> <Icon.Twitter size={40} /></a>*/}
              {/*</div>*/}
              <div className="my-5 text-center">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.kntopasswallet"
                >
                  <img
                    src={require("../assets/img/play-store.png")}
                    width="150"
                    className="mb-4"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://apps.apple.com/eg/app/knto-pass/id6467032709"
                >
                  {" "}
                  <img
                    src={require("../assets/img/app-store.png")}
                    width="150"
                    className="mb-4"
                  />
                </a>
              </div>
            </Col>
            <Col>
              <div className="px-5 content-text-center">
                <h3 className="mb-3">Contact Us</h3>
                <p className="fs-6">
                  <strong className="me-3">
                    {" "}
                    <Icon.PinMapFill size={20} />{" "}
                  </strong>{" "}
                  Cairo - Egypt
                </p>
                <p className="fs-6">
                  <strong className="me-3">
                    {" "}
                    <Icon.TelephoneOutboundFill size={20} />{" "}
                  </strong>{" "}
                  +20 111 167 2926
                </p>
                <p className="fs-6">
                  <strong className="me-3">
                    {" "}
                    <Icon.EnvelopeAtFill size={20} />{" "}
                  </strong>{" "}
                  <a href="mailto:info@kntopass.com" className="text-white">
                    info@kntopass.com
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <footer className="py-3 border-top text-center">
        <div className="align-items-center px-5">
          <p class="mb-0 text-body-secondary">
            {" "}
            © {new Date().getFullYear()}{" "}
            <strong>
              {" "}
              <strong className="text-primary">KNTO Pass</strong> powred by{" "}
              <a
                target="_blank"
                href="https://fronixstudio.com"
                className="text-decoration-none"
                style={{ color: "#d71920" }}
              >
                <strong>Fronix Studio</strong>{" "}
              </a>{" "}
            </strong>
          </p>
        </div>
      </footer>
    </>
  );
};
export default LandingFooter;
