import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthenticationUtility/AuthContext";

const BusinessHeader = (props) => {
  const { business } = useAuth();

  return (
    <>
      <span className={props.textColor}>
        Business:{" "}
        <Link
          to={business && "/business/projects/list/" + business.id}
          className={"fw-bold " + props.textColor}
        >
          <strong>{business && business.name}</strong>
        </Link>{" "}
      </span>
    </>
  );
};

export default BusinessHeader;
