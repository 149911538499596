import ApiBase from '../Apis/Base'

const url = "api/moderator/access_module_features/"

export default {
    async GetTotalFeaturesPrice(logout,accessModuleId) {
        try {
             const res = await ApiBase.apiBase(url + 'get_total_features_price/' + accessModuleId, 'GET',logout)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    }
}
