import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import apiAccounts from "../Apis/Accounts"; // Import your API service for account actions
import HelparAlert from "../Helpars/Alert"; // Importing HelparAlert component
import { Link } from "react-router-dom";

const ResendEmailConfirmation = () => {
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState({
    status: null,
    message: "",
    errors: [],
  });

  const handleResendEmail = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    // Reset alerts before validation
    setAlert({ status: null, message: "", errors: [] });

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        const response = await apiAccounts.ResendEmailConfirmation({
          email,
        });
        if (response) {
          setAlert({
            status: "success",
            message: "Confirmation email has been resent successfully.",
            errors: [],
          });
        }
      } catch (error) {
        setAlert({
          status: "error",
          message: "Failed to resend confirmation email",
          errors: [error.response?.data?.message || "An error occurred"],
        });
      }
    }

    setValidated(true);
  };

  return (
    <Container>
      {alert.status !== "success" ? (
        <>
          <h2 className="mt-3">Resend Email Confirmation</h2>
          <h5 className="mb-5">
            Enter your email address to resend the confirmation link.
          </h5>

          {/* Display HelparAlert */}
          <HelparAlert
            status={alert.status}
            message={alert.message}
            errors={alert.errors}
          />

          <Form noValidate validated={validated} onSubmit={handleResendEmail}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="email"
                size="lg"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                A valid email is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">
              Resend Confirmation
            </Button>
          </Form>
        </>
      ) : (
        <>
          <p className="fw-500 fs-4 mt-3">{alert.message}</p>
          <Link to="/account/login" className="text-decoration-none">
            Go to Login
          </Link>
        </>
      )}
    </Container>
  );
};

export default ResendEmailConfirmation;
