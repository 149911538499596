import React from "react";
import { Alert } from "react-bootstrap";

const HelparAlert = (props) => {
  return (
    <div>
      {props && props.message ? (
        props.status === "error" ? (
          <Alert variant="danger">
            {props.message}
            {props.errors != null ? (
              <ul>
                {" "}
                {props.errors.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Alert>
        ) : props.status === "success" ? (
          <Alert variant="success">{props.message}</Alert>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </div>
  );
};
export default HelparAlert;
