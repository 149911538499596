import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Form, Button, InputGroup } from 'react-bootstrap';
import apiReaders from '../Apis/Readers';
import HelparAlert from '../Helpars/Alert';
import { useAuth } from '../AuthenticationUtility/AuthContext';


const ReadersPartialCreate = ({ ListReaders  }) => {
    const { logout } = useAuth();

    const params = useParams();
    const [isLoading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [serialNumber, setSerailNumber] = useState('');

    const [status, setStatus] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState('');


    const [validated, setValidated] = useState(false);

    const onChangeName = (event) => {
        setName(event.target.value);
    }
    const onChangeSerailNumber = (event) => {
        setSerailNumber(event.target.value);
    }
     
    const onSubmitReader = async (event) => {
        setLoading(true);
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }
        else {
            event.preventDefault();
            let ReadersObj = {
                name: name,
                serial_number: serialNumber
            }
            let response = await apiReaders.Create(logout,params.hash_id, ReadersObj)
            setStatus(response.status)
            setMessage(response.message)
            setErrors(response.errors)

            if (response.status === "success") {
                setName('');
                setSerailNumber('');
                ListReaders()
            }
        }
        setLoading(false);
    }
    return (
        <Card className="shadow-sm p-3 mb-5 rounded border border-0">
            <Card.Body className="d-flex">
                <Form noValidate validated={validated} id='my-form' onSubmit={onSubmitReader} className="w-100" >
                    <HelparAlert status={status} message={message} errors={errors} ></HelparAlert>
                    <Form.Group className="mb-3">
                        <Form.Label>Reader Name</Form.Label>
                        <Form.Control value={name} onChange={onChangeName} placeholder="Enter name" required />
                        <Form.Control.Feedback type="invalid">
                            name is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Serail Number</Form.Label>
                        <Form.Control value={serialNumber} onChange={onChangeSerailNumber} placeholder="Enter serail number" required />
                        <Form.Control.Feedback type="invalid">
                        Serail Number is required
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" form='my-form'
                        variant="primary" size="lg"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Loading…' : 'submit'}
                    </Button>

                </Form>
            </Card.Body>
        </Card>
    );
}
export default ReadersPartialCreate;