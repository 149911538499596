import { Modal, Button } from "react-bootstrap";

const DeleteConfirmationDialog = ({
  id,
  isLoading,
  isOpen,
  onClose,
  onDelete,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      show={isOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="text-center">
          <p>Are you sure you want to delete this item?</p>
          <Button
            variant="danger"
            onClick={() => onDelete(id)}
            disabled={isLoading}
          >
            {" "}
            {isLoading ? "Loading…" : "delete"}
          </Button>{" "}
          <Button variant="primary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteConfirmationDialog;
