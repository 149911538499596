import React from "react";
import { Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
 import LayoutProjects  from "../Layouts/LayoutProjects";
import * as Icon from "react-bootstrap-icons";

const AccessModulesActions = () => {
  const params = useParams();
  return (
    <LayoutProjects
      rootName="Access Modules"
      currentRootName="Create successfully"
      rootLink={"/business/projects/access-modules/list/" + params.hash_id}
    >
      <Card className="shadow-sm  mb-2 rounded border border-0 text-center ">
        <Card.Body>
          <Card.Title className="text-success p-3 fs-3">
            Create access module successfully
          </Card.Title>
        </Card.Body>
        <Card.Footer className="text-muted p-3">
          <Link
            to={
              "/business/projects/accesses/create/" +
              params.access_model_id +
              "/" +
              params.hash_id
            }
            className="btn btn-sm btn-primary me-5"
          >
            <Icon.TicketFill size={15} /> Send Access
          </Link>
          <Link
            to={
              "/business/projects/forms/create/" +
              params.access_model_id +
              "/" +
              params.hash_id
            }
            className="btn btn-sm btn-primary me-5"
            disabled={true}
          >
            <Icon.FileEarmarkPlusFill size={15} /> Create Form
          </Link>
          <Link
            to={"/business/projects/access-modules/list/" + params.hash_id}
            className="text-decoration-none"
          >
            <Icon.ArrowLeft size={25} /> Go To Access Module
          </Link>
        </Card.Footer>
      </Card>
    </LayoutProjects>
  );
};
export default AccessModulesActions;
