import ApiBase from "./Base";

const url = "api/access_logs/";

export default {
  async list(
    logout,
    hash_id,
    searchText = "",
    status = "",
    loggedFrom = "",
    loggedTo = "",
    reader_id = "",
    access_module_id = "",
    form_id = "",
    page,
    per
  ) {
    try {
      const response = await ApiBase.apiBase(
        url +
          "list/" +
          hash_id +
          "?search_text=" +
          searchText +
          "&status=" +
          status +
          "&date_from=" +
          loggedFrom +
          "&date_to=" +
          loggedTo +
          "&reader_app_id=" +
          reader_id +
          "&access_module_id=" +
          access_module_id +
          "&form_id=" +
          form_id +
          "&page=" +
          page +
          "&per=" +
          per,
        "GET",
        logout
      );
      return response;
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error:", error);
    }
  },
};
