/* eslint-disable import/no-anonymous-default-export */
 import ApiBase from '../Apis/Base'

const url = "api/users/"

export default {
     async Get(logout) {
        try {
            const res = await ApiBase.apiBase(url + 'get', 'GET',logout)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
