import ApiBase from './Base'

const url = "api/business_categories/"

export default {
    async list(logout) {
        try {
             const response = await ApiBase.apiBase(url + 'list/' , 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },


}
