import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import PageLoading from "./Helpars/PageLoading";
import { Layout } from "./Layouts/Layout";
import apiTermsAndConditions from "./Apis/TermsAndConditions";
import HelparAlert from "./Helpars/Alert";
import { useAuth } from "./AuthenticationUtility/AuthContext";

const TermsAndConditions = () => {
  const { logout } = useAuth();
  const params = useParams();
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [isPageLoading, setPageLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState("");
 
  // Define GetTermsAndConditions using useCallback
  const GetTermsAndConditions = useCallback(async () => {
    setPageLoading(true);
    try {
      let response = await apiTermsAndConditions.GetByType(logout, params.type);
      setTermsAndConditions(response.data);
      setStatus(response.status);
      setMessage(response.message);
      setErrors(response.errors);
    } catch (error) {
      setStatus("error");
      setMessage("Failed to fetch terms and conditions.");
      setErrors([error.message]);
    } finally {
      setPageLoading(false);
    }
  }, [logout, params.type]); // Add logout and params.type as dependencies

  // Use the GetTermsAndConditions function in useEffect
  useEffect(() => {
    GetTermsAndConditions();
  }, [GetTermsAndConditions]);

  return (
    <Layout
      rootName="Readers"
      buttonUrl={"/business/projects/reader-app/create/" + params.hash_id}
      buttonName="Create New Reader"
    >
      {isPageLoading ? (
        <PageLoading />
      ) : (
        <>
          <HelparAlert
            status={status}
            message={message}
            errors={errors}
          ></HelparAlert>
          <section>
            <h2>{termsAndConditions?.title}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: termsAndConditions?.content }}
            />
          </section>
        </>
      )}
    </Layout>
  );
};

export default TermsAndConditions;
