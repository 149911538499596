import React from "react";

const LandingBusinessDashboard = () => {
  return (
    <section id="BusinessDashboard" class="pt-3 pt-md-11 mt-4">
      <div class="container">
        <div className="row">
          <div className="col-md-6 text-left mb-3">
            <img src={require("../assets/img/dashboard.png")} width="60%" />
          </div>
          <div className="d-flex col-md-6">
            <div class="align-items-center align-content-center align-self-center">
              <h1 className="fs-2 fw-bold mb-5">Business Dashboard</h1>
              <p class="lead mb-6 mb-lg-8 fs-3 text-primary">
                The Best Dashboard to Distribute & Manage Your Business
              </p>
              <ul className="fs-5" style={{ color: "#6e6e6e" }}>
                <li>Create & Manage Scanner to Reader App </li>
                <li>
                  Manage User Subscriptions & Access Settings on Access Modules
                </li>
                <li>Manage & Distribute Access</li>
                <li>Manage Invitations</li>
                <li>Manage Forms for Public Distribution Access</li>
                <li>Manage Forms Access Request Approval </li>
                <li>Manage Access Logs</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default LandingBusinessDashboard;
