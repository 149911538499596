import ApiBase from './Base'

const url = "api/access_module_forms/"

export default {
    async list(logout,hash_id, access_model_id, search_text = '', page, per) {
        try {
            const response = await ApiBase.apiBase(url + 'list/' + access_model_id + '/' + hash_id + '?search_text=' + search_text + '&page=' + page + '&per=' + per, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async GetById(logout,hash_id, id) {
        try {
            const response = await ApiBase.apiBase(url + 'get_by_id/' + id + '/' + hash_id, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async GetByHashId(logout,hash_id) {
        try {
            const response = await ApiBase.apiBase(url + 'get_by_hash_id/' + hash_id, 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Create(logout,accessModuleId, hash_id, body) {
        try {
            const urls = url + 'create/' + accessModuleId + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, 'POST',logout, body,false, true)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Update(logout,id, hash_id, body) {
        try {
            const urls = url + 'update/' + id + "/" + hash_id;
            const response = await ApiBase.apiBase(urls, 'PUT', body,logout,false, true)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Delete(logout,id, hash_id) {
        try {
            const urls = url + 'delete/' + id + '/' + hash_id;
            const response = await ApiBase.apiBase(urls, 'DELETE',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
