import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const CustomFieldsCreate = ({ customFieldData, setCustomFieldData }) => {
  const [fieldIndex, setFieldIndex] = useState(0);
  useEffect(() => {
    if (customFieldData) {
      const updatedCustomField = customFieldData.map((item, index) => ({
        ...item,
        id: String(index + 1), // or any other logic to update the 'id'
      }));
      setCustomFieldData(updatedCustomField);
      setFieldIndex(customFieldData.length);
    }
  }, []);
  const addTextBox = (index) => {
    let id = index + 1;
    setCustomFieldData([
      ...customFieldData,
      {
        id: String(id),
        type: "input",
        label: "",
        value: "",
        is_required: false,
        data_type: "text",
      },
    ]);
    setFieldIndex(id);
  };
  const addDropdown = (index) => {
    let id = index + 1;
    setCustomFieldData([
      ...customFieldData,
      {
        id: String(id),
        type: "dropdown",
        label: "",
        value: "",
        options: [""],
        is_required: false,
        data_type: "",
      },
    ]);
    setFieldIndex(id);
  };
  const addDropdownOption = (index) => {
    const updatedCustomField = [...customFieldData];
    updatedCustomField[index].options = [
      ...updatedCustomField[index].options,
      "",
    ];
    setCustomFieldData(updatedCustomField);
  };
  const removeDropdownOption = (outIndex, index) => {
    const updatedCustomField = [...customFieldData];
    updatedCustomField[outIndex].options.splice(index, 1);
    setCustomFieldData(updatedCustomField);
  };
  const handleDropdownOptionChange = (outIndex, index, value) => {
    const updatedCustomField = [...customFieldData];
    updatedCustomField[outIndex].options[index] = value;
    setCustomFieldData(updatedCustomField);
  };
  const handleLabelChange = (index, value) => {
    const updatedTextBoxes = [...customFieldData];
    updatedTextBoxes[index].label = value;
    setCustomFieldData(updatedTextBoxes);
  };
  const handleTextBoxValueChange = (index, value) => {
    const updatedTextBoxes = [...customFieldData];
    updatedTextBoxes[index].value = value;
    setCustomFieldData(updatedTextBoxes);
  };
  const handleDataTypeChange = (index, value) => {
    const updatedTextBoxes = [...customFieldData];
    updatedTextBoxes[index].data_type = value;
    setCustomFieldData(updatedTextBoxes);
  };
  const onChangeIsRequired = (index) => {
    const updatedTextBoxes = [...customFieldData];
    updatedTextBoxes[index].is_required = !customFieldData[index].is_required;
    setCustomFieldData(updatedTextBoxes);
  };
  const removeField = (index) => {
    const updatedTextBoxes = [...customFieldData];
    updatedTextBoxes.splice(index, 1);
    setCustomFieldData(updatedTextBoxes);
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = Array.from(customFieldData);
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    setCustomFieldData(newItems);
  };
  return (
    <Row>
      <Col md={9}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {customFieldData &&
                  customFieldData.map((item, index) =>
                    item.type === "input" ? (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <Row
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="shadow-sm p-3 mb-3"
                          >
                            <Col>
                              <Form.Group Draggable className="mb-3 ">
                                <Form.Label>Label</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={item.label}
                                  onChange={(e) =>
                                    handleLabelChange(index, e.target.value)
                                  }
                                />
                              </Form.Group>
                              <Row>
                                <Col md={8}>
                                  <Form.Group className="mb-3  ">
                                    <Form.Label>Value</Form.Label>
                                    {item.data_type === "textarea" ? (
                                      <Form.Control
                                        as="textarea"
                                        value={item.value}
                                        onChange={(e) =>
                                          handleTextBoxValueChange(
                                            index,
                                            e.target.value
                                          )
                                        }
                                      />
                                    ) : (
                                      <Form.Control
                                        type={item.data_type}
                                        value={item.value}
                                        onChange={(e) =>
                                          handleTextBoxValueChange(
                                            index,
                                            e.target.value
                                          )
                                        }
                                      />
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={4}>
                                  <Form.Group
                                    className="mb-3"
                                    value={item.data_type}
                                    onChange={(e) =>
                                      handleDataTypeChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <Form.Label>Data Type</Form.Label>
                                    <Form.Select className="mb-3" required>
                                      <option value="text">text</option>
                                      <option value="textarea">
                                        Paragraph
                                      </option>
                                      <option value="number">number</option>
                                      <option value="email">email</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col>
                              <Form.Check
                                className="mb-3" // prettier-ignore
                                type="checkbox"
                                label="Is Required"
                                checked={item.is_required}
                                onChange={() => onChangeIsRequired(index)}
                              />
                              <Button
                                variant="link"
                                className="text-danger text-decoration-none p-0"
                                onClick={() => removeField(index)}
                              >
                                <Icon.Trash size={20} /> Remove
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Draggable>
                    ) : item.type === "dropdown" ? (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided) => (
                          <Row
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="shadow-sm p-3 mb-3"
                          >
                            <Col>
                              <Form.Group Draggable className="mb-3 ">
                                <Form.Label>Label</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={item.label}
                                  onChange={(e) =>
                                    handleLabelChange(index, e.target.value)
                                  }
                                />
                              </Form.Group>

                              <Form.Group className="mb-3  ">
                                <Form.Label>Options</Form.Label>
                                {customFieldData[index].options &&
                                  customFieldData[index].options.map(
                                    (option, optionIndex) => (
                                      <Row className="mb-2">
                                        <Col md={8}>
                                          <Form.Control
                                            type="text"
                                            value={option}
                                            onChange={(e) =>
                                              handleDropdownOptionChange(
                                                index,
                                                optionIndex,
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Col>
                                        <Col md={4}>
                                          <Button
                                            variant="link"
                                            className="text-danger text-decoration-none p-0"
                                            onClick={() =>
                                              removeDropdownOption(
                                                index,
                                                optionIndex
                                              )
                                            }
                                          >
                                            <Icon.Trash size={15} />{" "}
                                          </Button>
                                        </Col>
                                      </Row>
                                    )
                                  )}
                              </Form.Group>
                              <Button
                                variant="link"
                                className="text-primary text-decoration-none"
                                onClick={() => addDropdownOption(index)}
                              >
                                Add option
                              </Button>
                            </Col>
                            <Col>
                              <Form.Check
                                className="mb-3" // prettier-ignore
                                type="checkbox"
                                label="Is Required"
                                checked={item.is_required}
                                onChange={() => onChangeIsRequired(index)}
                              />
                              <Button
                                variant="link"
                                className="text-danger text-decoration-none p-0"
                                onClick={() => removeField(index)}
                              >
                                <Icon.Trash size={20} /> Remove
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </Draggable>
                    ) : (
                      ""
                    )
                  )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Col>
      <Col md={3}>
        <Card className="shadow-sm">
          <Card.Body className="text-center">
            <div className="pb-3">
              <Button
                variant="link"
                className="text-primary text-decoration-none"
                onClick={() => addTextBox(fieldIndex)}
              >
                <Icon.FileEarmarkFontFill size={20} /> Text input
              </Button>
            </div>
            <div>
              <Button
                variant="link"
                className="text-primary text-decoration-none"
                onClick={() => addDropdown(fieldIndex)}
              >
                <Icon.CaretDownFill size={20} /> Dropdown
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default CustomFieldsCreate;
