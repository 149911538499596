import React from 'react';
import { useNavigate  } from 'react-router-dom';
import { useAuth } from './AuthContext';

const LogoutComponent  = () => {
  const navigate = useNavigate();
  const { isLoggedIn, logout } = useAuth();

  
  if (!isLoggedIn) {
    return null;
  }
  return (
    <button onClick={() => logout(navigate)}>
      Logout
    </button>
  );
};

export default LogoutComponent;
