import React from "react";
 import LayoutProjects  from "../Layouts/LayoutProjects";
import AccessLogsPartialList from "./ListPartial";
const AccessLogsList = () => {
  return (
    <LayoutProjects rootName="Access Logs">
      <AccessLogsPartialList />
    </LayoutProjects>
  );
};
export default AccessLogsList;
