import React, { useState } from "react";
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import logo from "./assets/img/logo.svg"; // Replace with the actual path to your SVG file
import AuhtMenu from "./AuthenticationUtility/AuhtMenu";
import { useAuth } from "./AuthenticationUtility/AuthContext";


const NavMenu = () => {
  const { business } = useAuth();
  // State to handle navbar collapse
  const [collapsed, setCollapsed] = useState(true);

  // Toggle collapse state
  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <header>
      <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            <Link to="/" className="text-decoration-none text-dark">
              <Image src={logo} className="logo" />
            </Link>
          </Navbar.Brand>
          {/* Navbar toggle button */}
          <Navbar.Toggle onClick={toggleNavbar} />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="align-content-center align-items-center justify-content-center">
              <AuhtMenu />
              <Link
                to={business&&"/business/projects/list/"+business.id}
                className="text-decoration-none ms-2 fw-normal"
              >
                Your Business
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavMenu;
