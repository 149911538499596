import React, { useEffect } from "react";
import mobileApp from "./assets/img/play-app-stores.svg";

const MobileAppDownload = () => {
  useEffect(() => {
    GetTermsAndConditions();
  }, []);

  const GetTermsAndConditions = async () => {};
  return (
    <>
      <section class="pt-3 pt-md-11 mt-4">
        <div class="container">
          <div className="row">
            <div className="col-md-6 text-left  mb-3">
              <img src={mobileApp} width="300" />
            </div>
            <div className="d-flex col-md-6">
              <div class="align-items-center align-content-center align-self-center">
                <h1 class="fw-700" style={{ color: "#6e6e6e" }}>
                  Mobile App
                </h1>
                <p class="lead mb-6 mb-lg-8 fs-5" style={{ color: "#6e6e6e" }}>
                  One App, All Things Access.
                </p>
                <h3 class="fw-700 fs-5" style={{ color: "#6e6e6e" }}>
                  Download App.
                </h3>
                <div className="mt-3">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.kntopasswallet"
                  >
                    <img
                      src={require("./assets/img/play-store.png")}
                      width="150"
                      className="me-5"
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://apps.apple.com/eg/app/knto-pass/id6467032709"
                  >
                    {" "}
                    <img
                      src={require("./assets/img/app-store.png")}
                      width="150"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default MobileAppDownload;
