
import ApiBase from '../Apis/Base'

const url = "api/businesses/"

export default {
    async Create(logout,body) {
        try {
             const res = await ApiBase.apiBase(url + 'create', 'POST',logout, body)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Get(logout,) {
        try {
            const res = await ApiBase.apiBase(url + 'get', 'GET',logout)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    }
}
