import Cookies from 'js-cookie';

const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';

// Function to get the access token
export const getToken = () => {
  const token = Cookies.get(TOKEN_KEY);
  if (token) {
    return token;
  }
  return localStorage.getItem(TOKEN_KEY);
};

// Function to get the refresh token
export const getRefreshToken = () => {
  const refreshToken = Cookies.get(REFRESH_TOKEN_KEY);
  if (refreshToken) {
    return refreshToken;
  }
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

// Function to check if the user is logged in
export const isLoggedIn = () => {
  return !!getToken();
};

// Function to set both access and refresh tokens
export const setToken = (token, refreshToken, remember) => {
  if (remember) {
    Cookies.set(TOKEN_KEY, token, { expires: 7 }); // Token expires in 7 days
    Cookies.set(REFRESH_TOKEN_KEY, refreshToken, { expires: 30 }); // Refresh token expires in 7 days
  } else {
    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }
};

// Function to remove both access and refresh tokens
export const removeToken = () => {
  Cookies.remove(TOKEN_KEY);
  Cookies.remove(REFRESH_TOKEN_KEY);
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};
