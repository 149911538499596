import ApiBase from '../Apis/Base'

const url = "api/business_requests/"
const urlModerator = "api/moderator/business_requests/"

export default {
    async Create(logout,body) {
        try {
             const res = await ApiBase.apiBase(url + 'create' , 'POST',logout, body)
            return res;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async list(logout,) {
        try {
             const response = await ApiBase.apiBase(urlModerator + 'list/' , 'GET',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Action(logout,id, body) {
        try {
             const response = await ApiBase.apiBase(urlModerator + 'action/' + id , 'POST',logout, body)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
