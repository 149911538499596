import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import apiAccounts from "../Apis/Accounts";
import { Link } from "react-router-dom";
import HelparAlert from "../Helpars/Alert"; // Importing HelparAlert component

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState({
    status: null,
    message: "",
    errors: [],
  });

  const handleRegister = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    // Reset alerts before validation
    setAlert((prevAlert) => ({
      ...prevAlert,
      status: null,
      message: "",
      errors: [],
    }));
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else if (password !== confirmPassword) {
      setAlert((prevAlert) => ({
        ...prevAlert,
        status: "error",
        message: "Passwords do not match",
      }));

      return;
    } else {
      const registerData = {
        frist_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        confirm_password: confirmPassword,
      };

      try {
        const response = await apiAccounts.Register(registerData);

        if (response && response.data) {
        } else {
          setAlert((prevAlert) => ({
            ...prevAlert,
            status: response.status,
            message: response.message,
            errors: response.errors,
          }));
        }
      } catch (error) {
        setAlert((prevAlert) => ({
          ...prevAlert,
          status: "error",
          message: "Registration failed",
          errors: [error.response?.data?.message || "An error occurred"],
        }));
      }
    }

    setValidated(true);
  };

  return (
    <Container>
      {alert.status !== "success" ? (
        <>
          <h2 className="mt-3">Register</h2>
          <h5 className="mb-5">Create a new account.</h5>

          {/* Display HelparAlert */}

          <HelparAlert
            status={alert.status}
            message={alert.message}
            errors={alert.errors}
          />

          <Form noValidate validated={validated} onSubmit={handleRegister}>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Control
                    type="text"
                    size="lg"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    First Name is required.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="lastName">
                  <Form.Control
                    type="text"
                    size="lg"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Last Name is required.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="email">
              <Form.Control
                type="email"
                size="lg"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                A valid email is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Control
                type="password"
                size="lg"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Password is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Control
                type="password"
                size="lg"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Confirming your password is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">
              Register
            </Button>
          </Form>
          <p className="mt-3">
            If you already have an account,{" "}
            <Link to="/account/login" className="text-decoration-none">
              login
            </Link>
            .
          </p>
        </>
      ) : (
        <p className="fw-500 fs-4 mt-3">{alert.message}</p>
      )}
    </Container>
  );
};

export default Register;
