 import ApiBase from './Base'

const url = "api/account/manage/"

export default {
    async UpdateProfile(logout,body) {
        try {
             const urls = url + 'update_profile/';
            const response = await ApiBase.apiBase(urls , 'PUT',logout, body,false,true)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async ChangePassword(logout,body) {
        try {
             const urls = url + 'change_password/';
            const response = await ApiBase.apiBase(urls , 'PUT',logout, body)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
    async Delete(logout) {
        try {
             const urls = url + 'delete_account/';
            const response = await ApiBase.apiBase(urls , 'DELETE',logout)
            return response;
        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error:', error);
        }
    },
}
