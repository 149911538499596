import React from 'react';
import { useParams } from 'react-router-dom';

import ReadersPartialUpdate from './PartialUpdate';

import LayoutProjects  from '../Layouts/LayoutProjects';

const ReadersUpdate = () => {
    const params = useParams();

    return (
        <LayoutProjects rootName="Readers" currentRootName="Update" rootLink={"/projects/readers/list/" + params.hash_id}  >
            <ReadersPartialUpdate />
        </LayoutProjects>
    );
}
export default ReadersUpdate;