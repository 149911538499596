import LandingIndex from "./components/LandingPage/Index";
import   MobileAppDownload   from "./components/DownloadApp";
import { SupportContactUs } from "./components/Support/ContactUs";
import { LayoutBusinesses } from "./components/Layouts/LayoutBusinesses";
import { Layout } from "./components/Layouts/Layout";
import { LayoutManageAccount } from "./components/Layouts/LayoutManageAccount";
import { LayoutModerator } from "./components/Moderator/Layout";
import { LayoutAccount } from "./components/Layouts/LayoutAccount";

import ProjectCreate from "./components/Projects/Create";
import ProjectUpdate from "./components/Projects/Update";
import ProjectList from "./components/Projects/List";

import BusinessIndex from "./components/Projects/index";

import ReaderAppList from "./components/ReaderApps/List";
import ReaderAppCreate from "./components/ReaderApps/Create";
import ReaderAppUpdate from "./components/ReaderApps/Update";
import ReaderAppChangePassword from "./components/ReaderApps/ChangePassword";
import ReaderAppAccessLogs from "./components/ReaderApps/AccessLogs";
import ReaderAppAccesses from "./components/ReaderApps/Accesses";

//----Readers--------
import ReadersList from "./components/Readers/List";
import ReadersCreate from "./components/Readers/Create";
import ReadersUpdate from "./components/Readers/Update";

import AccessModulesList from "./components/AccessModules/List";
//import AccessModulesCreate from "./components/AccessModules/Create";
import AccessModulesActions from "./components/AccessModules/Actions";
import AccessModuleUpdate from "./components/AccessModules/Update";
import AccessModuleAccessLogs from "./components/AccessModules/AccessLogs";
import AccessModuleAccesses from "./components/AccessModules/Accesses";

import FormCreate from "./components/Forms/Create";
import FormUpdate from "./components/Forms/Update";
import FormList from "./components/Forms/List";
import FormsAccessLogs from "./components/Forms/AccessLogs";
import FormsAccesses from "./components/Forms/Accesses";
import FormIndex from "./components/Forms/Index";

import AccessesCreate from "./components/Accesses/Create";
import AccessesList from "./components/Accesses/List";
import AccessesRequestsList from "./components/AccessesRequests/List";

import AccessInvitationsList from "./components/AccessInvitations/List";
import AccessInvitationsStaticQrCode from "./components/AccessInvitations/StaticQrCode";

import AccessLogsList from "./components/AccessLogs/List";

import ModeratorBusinessesRequests from "./components/Moderator/Businesses/Requests";
import ModeratorBusinessesCreate from "./components/Moderator/Businesses/Create";
import ModeratorAccessModulesList from "./components/Moderator/AccessModules/List";
import ModeratorAccessModulesCreate from "./components/Moderator/AccessModules/Create";
import ModeratorAccessModulePackagesCreate from "./components/Moderator/AccessModulePackages/Create";
import ModeratorAccessModuleAssign from "./components/Moderator/AccessModules/Assign";

import BusinessRequest from "./components/Businesses/Request";

import ManageAccount from "./components/Account/Manage/Index";
import EmailUnsubscribe from "./components/Account/Manage/EmailUnsubscribe";

//----Account --------
import AccountLogin from "./components/Account/Login";
import AccountRegister from "./components/Account/Register";
import AccountConfirmEmail from "./components/Account/ConfirmEmail";
import AccountResendEmailConfirmation from "./components/Account/ResendEmailConfirmation";
import AccountForgotPassword from "./components/Account/ForgotPassword";
import AccountResetPassword from "./components/Account/ResetPassword";

import TermsAndConditions from "./components/TermsAndConditions";


const AppRoutes = [
    {
        index: true,
        element: <LandingIndex />
    },
    {
        path: '/support/contact-us',
        element: <Layout> <SupportContactUs /></Layout>
    },{
        path: '/mobile-app/download',
        element: <Layout> <MobileAppDownload /></Layout>
    },
    {
        path: '/business/projects/create',
        element: <LayoutBusinesses><ProjectCreate /></LayoutBusinesses>
    },
    {
        path: '/business/projects/update/:hash_id',
        element: <ProjectUpdate />
    },
    {
        path: '/business/projects/list/:bussines_id',
        requireAuth: true,
        element: <LayoutBusinesses><ProjectList /></LayoutBusinesses>
    }, {
        path: '/business/projects/:hash_id',
        requireAuth: true,
        element: <BusinessIndex />
    }, {
        path: '/business/projects/reader-app/list/:hash_id',
        requireAuth: true,
        element: <ReaderAppList />
    }, {
        path: '/business/projects/reader-app/create/:hash_id',
        requireAuth: true,
        element: <ReaderAppCreate />,
    }, {
        path: '/business/projects/reader-app/update/:id/:hash_id',
        requireAuth: true,
        element: <ReaderAppUpdate />,
    }, {
        path: '/business/projects/reader-app/change-password/:id/:name/:hash_id',
        requireAuth: true,
        element: <ReaderAppChangePassword />,
    }, {
        path: '/business/projects/reader-app/access_logs/:id/:name/:hash_id',
        requireAuth: true,
        element: <ReaderAppAccessLogs />,
    }, {
        path: '/business/projects/reader-app/accesses/:id/:name/:hash_id',
        requireAuth: true,
        element: <ReaderAppAccesses />,
    },
    //---- Readers--------
    {
        path: '/business/projects/readers/list/:hash_id',
        requireAuth: true,
        element: <ReadersList />
    }, {
        path: '/business/projects/readers/create/:hash_id',
        requireAuth: true,
        element: <ReadersCreate />,
    }, {
        path: '/business/projects/readers/update/:id/:hash_id',
        requireAuth: true,
        element: <ReadersUpdate />,
    } ,

    {
        path: '/business/projects/access-modules/list/:hash_id',
        requireAuth: true,
        element: <AccessModulesList />
    },
    //{
    //    path: '/business/projects/access-modules/create/:hash_id',
    //    requireAuth: true,
    //    element: <AccessModulesCreate />
    //},
    {
        path: '/business/projects/access-modules/actions/:access_model_id/:hash_id',
        requireAuth: true,
        element: <AccessModulesActions />
    },
    {
        path: '/business/projects/access-modules/update/:id/:hash_id',
        requireAuth: true,
        element: <AccessModuleUpdate />
    }, {
        path: '/business/projects/access-modules/access-logs/:id/:name/:hash_id',
        requireAuth: true,
        element: <AccessModuleAccessLogs />
    }, {
        path: '/business/projects/access-modules/accesses/:id/:name/:hash_id',
        requireAuth: true,
        element: <AccessModuleAccesses />
    },
    {
        path: '/business/projects/forms/create/:access_model_id/:hash_id',
        requireAuth: true,
        element: <FormCreate />
    },
    {
        path: '/business/projects/forms/create/:hash_id',
        requireAuth: true,
        element: <FormCreate />
    },
    {
        path: '/business/projects/forms/update/:id/:access_model_id/:hash_id',
        requireAuth: true,
        element: <FormUpdate />
    },
    {
        path: '/business/projects/forms/list/:hash_id',
        requireAuth: true,
        element: <FormList />
    },
    {
        path: '/business/projects/forms/list/:access_module_id/:hash_id',
        requireAuth: true,
        element: <FormList />
    }, {
        path: '/business/projects/forms/access-logs/:id/:name/:hash_id',
        requireAuth: true,
        element: <FormsAccessLogs />
    }, {
        path: '/business/projects/forms/accesses/:id/:name/:hash_id',
        requireAuth: true,
        element: <FormsAccesses />
    },
    {
        path: '/form/:hash_id',
        element: <FormIndex />
    },
    {
        path: '/business/projects/accesses/create/:access_model_id/:hash_id',
        requireAuth: true,
        element: <AccessesCreate />
    },
    {
        path: '/business/projects/accesses/create/:hash_id',
        requireAuth: true,
        element: <AccessesCreate />
    }, {
        path: '/business/projects/accesses/list/:hash_id',
        requireAuth: true,
        element: <AccessesList />
    }, {
        path: '/business/projects/accesses/requests/list/:hash_id',
        requireAuth: true,
        element: <AccessesRequestsList />
    }, {
        path: '/business/projects/access-logs/list/:hash_id',
        requireAuth: true,
        element: <AccessLogsList />
    }, {
        path: '/business/projects/accesses/invitations/list/:access_parent_id/:hash_id',
        requireAuth: true,
        element: <AccessInvitationsList />
    }
    , {
        path: '/business/projects/accesses/invitations/list/:hash_id',
        requireAuth: true,
        element: <AccessInvitationsList />
    },
    {
        path: '/accesses/invitation-static-link/:access_hash_id',
        element: <AccessInvitationsStaticQrCode />
    }
    , {
        path: '/terms-and-conditions/:type',
        element: <TermsAndConditions />
    },
    {
        path: '/business/request',
        element: <Layout><BusinessRequest /></Layout>
    }
    , {
        path: '/moderator/business/create',
        requireAuth: true,
        element: <LayoutModerator><ModeratorBusinessesCreate /></LayoutModerator>
    }, {
        path: '/moderator/business/requests',
        requireAuth: true,
        element:  <ModeratorBusinessesRequests /> 
    }, {
        path: '/moderator/access-modules/list',
        requireAuth: true,
        element:  <ModeratorAccessModulesList /> 
    }, {
        path: '/moderator/access-modules/create',
        requireAuth: true,
        element: <LayoutModerator><ModeratorAccessModulesCreate /></LayoutModerator>
    }, {
        path: '/moderator/access-modules/assign/:access_module_id',
        requireAuth: true,
        element: <LayoutModerator><ModeratorAccessModuleAssign /></LayoutModerator>
    }, {
        path: '/moderator/access-modules-packages/create/:access_module_id', 
        requireAuth: true,
        element: <LayoutModerator><ModeratorAccessModulePackagesCreate /></LayoutModerator>
    }, {
        path: '/account/manage',
        requireAuth: true,
        element: <LayoutManageAccount><ManageAccount /></LayoutManageAccount>
    }, 
     //----Account --------
     {
        path: '/account/login',
        element: <LayoutAccount><AccountLogin /></LayoutAccount>
    },
    {
        path: '/account/register',
        element: <LayoutAccount><AccountRegister /></LayoutAccount>
    },{
        path: '/account/confirm_email',
        element: <LayoutAccount><AccountConfirmEmail /></LayoutAccount>
    },{
        path: '/account/resend_email_confirmation',
        element: <LayoutAccount><AccountResendEmailConfirmation /></LayoutAccount>
    },{
        path: '/account/forgot_password',
        element: <LayoutAccount><AccountForgotPassword /></LayoutAccount>
    },
     {
        path: '/account/reset_password',
        element: <LayoutAccount><AccountResetPassword /></LayoutAccount>
    },

    {
        path: '/account/manage/email-unsubscribe/:uid',
        element: <LayoutManageAccount><EmailUnsubscribe /></LayoutManageAccount>
    },
];

export default AppRoutes;
